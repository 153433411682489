/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --purple-500: #9333EA;
    --purple-600: #7E22CE;
    --purple-700: #6B21A8;
  }
  
  body {
    @apply bg-[#1E1E2E] text-white;
  }
  
.fixed-height-title {
  min-height: 80px; /* Adjust based on your title's expected size */
}

.fixed-height-tagline {
  min-height: 120px; /* Ensure this is enough space for multiple lines */
  overflow: hidden; /* Prevents content from shifting */
}

/* Added fade-in transition for the tagline */
.transition-opacity {
  transition-property: opacity; 
  transition-duration: 2500ms;
}

/* Simplified Blinking Animation */
@keyframes blink {
  0%, 50%, 100% {
    opacity: 1;
  }
  50.01%, 100% {
    opacity: 0;
  }
}

/* Fade-Out Animation */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Caret Styling */
.blinking-caret {
  display: inline-block;
  width: 2px; /* Width of the caret */
  height: 1em; /* Aligns with the text height */
  background-color: rgb(206, 6, 212); /* Caret color */
  margin-left: 4px; /* Spacing between text and caret */
  vertical-align: bottom; /* Aligns caret vertically with text */
  animation: blink 1s steps(1) infinite;
  transition: opacity 1s ease; /* Transition for fade-out */
}

/* Fade-Out Class */
.fade-out {
  animation: fadeOut 1s forwards;
}


  .mouse {
    width: 50px;
    height: 90px;
    border: 3px solid rgb(215, 167, 245); /* Neon purple color */
    border-radius: 60px;
    position: relative;
  }
  
  .mouse::before {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(221, 170, 252); /* Neon purple for the wheel */
    border-radius: 50%;
    opacity: 1;
    animation: wheel 2s infinite;
  }
  
  @keyframes wheel {
    to {
      opacity: 0;
      top: 60px;
    }
  }
  